<template>
    <div>
        <div class="fly-breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem to="/">主界面</BreadcrumbItem>
                <BreadcrumbItem>{{'分组-'+b_group.name}}</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="template-list-root">
        
            <div class="template-l-opetion-bar">
                <Input suffix="ios-search" placeholder="输入名称" class="template-list-search template-list-bar-item"
                    @on-enter="loadData" @on-click="loadData" v-model="searchText" ></Input>
                
                <Button type="primary" @click="deleteTemplate()" class="template-list-bar-item" v-if="$context.isLogin" :disabled="this.selectRows.length==0">删除</Button>
                <Button type="primary" :to="'/templateEdit/g-'+groupId" class="template-list-bar-item"  v-if="$context.isLogin">新增打印模板</Button>
            </div>
            <div class="template-list-table">
                <Table :key="$context.isLogin" :columns="columns" :data="datas" @on-selection-change="selectionChange"></Table>
            </div>
            <div class="template-list-page">
                <Page :total="pages.total" :current="pages.current" :show-total="true" :page-size="pages.pageSize"
                    @on-change="loadCurrentPage" show-elevator  />
            </div>
        </div>
    </div>

</template>

<script>

var btnRender = function(that){

    return function(h, params){
        var btns = [];
        if(that.$context.isLogin){
            btns.push(h('i-button', {
                props: {
                    type: 'default',
                    size: 'small',
                    shape:'circle',
                    icon:'md-create' //修改
                },
                style:{
                    "margin-left":"10px",
                    "display":that.$context.isLogin?'inline-block':'none'
                },
                attrs: {
                    title: '修改'
                },
                on:{
                    click:function(event){
                        that.$router.push(`/templateEdit/${params.row.id}`)
                    }
                }
            }));
            btns.push(h('i-button', {
                props: {
                    type: 'default',
                    size: 'small',
                    shape:'circle',
                    icon:'md-trash' //删除
                },
                attrs: {
                    title: '删除'
                },
                style:{
                    "margin-left":"10px",
                    "display":that.$context.isLogin?'inline-block':'none'
                },
                on:{
                    click:function(event){
                        that.deleteTemplate([params.row]);
                    }
                }
            }));
        }
        btns.push(h('i-button', {
            props: {
                type: 'primary',
                size: 'small',
                shape:'circle',
                icon:'md-download' //下载
            },
            style:{
                "margin-left":"10px"
            },
            attrs: {
                title: '下载'
            },
            on:{
                click:function(event){
                    that.$router.push('/templateDownload/'+params.row.id);
                }
            }
        }));
        return h('div',{
            style:{
                "text-align":"right"
            }
        }, btns);
    };
}



export default{

    data(){
        let response = $.ajax({
            url: "/group/getById/"+this.$route.params.groupId,
            async: false,
            type: 'POST'
        });
        let res = JSON.parse(response.responseText);
        return {
            b_group:res,
            groupId:this.$route.params.groupId,
            searchText:'',
            selectRows:[],
            columns:[{
                title: '模板名称',
                key: 'name',
                width: 250
            },
            {
                title: '模板描述',
                key: 'description'
            },
            {
                title: '操作',
                key: 'action',
                width: 250,
                render:btnRender(this)
            }],
            datas:[],
            pages:{
                current:1,
                total:0,
                pageSize:10
            }
        }
    },
    methods:{
        loadData:function(){
            var that = this;
            $.ajax({
                url: "/template/queryCount",
                async: true,
                type: 'POST',
                headers: {'Content-Type': 'application/json'},
                
                data: JSON.stringify({
                    parentId:this.groupId,
                    search:this.searchText,
                    start:-1,
                    count:-1
                }),
                error:function(){
                    that.$Message.error('刷新失败');
                },
                success:function(result){
                    var nl = that.pages.current==1&&result!=0;
                    that.pages.current=1;
                    that.pages.total=result;
                    if(result==0){
                        that.datas = [];
                    }
                    if(nl){
                        that.loadCurrentPage();
                    }else{
                        that.$forceUpdate();
                    }
                },
            });
        },
        deleteTemplate:function(rows){
            var that = this;
            this.$Modal.confirm({
                title:"确认",
                content:"是否进行删除操作？",
                onOk:function(){
                    if(rows==null){
                        rows = that.selectRows;
                    }
                    
                    var ids = [];
                    for(let r in rows){
                        ids.push(rows[r].id);
                    }
                    $.ajax({
                        url: "/template/delete",
                        async: true,
                        type: 'POST',
                        data: JSON.stringify(ids),
                        headers: {'Content-Type': 'application/json'},
                        success:function(){
                            that.loadData();
                            that.$Message.info('删除成功');
                        },
                        error:function(){
                            that.$Message.error('删除失败');
                        }
                    });
                }
            })
            
        },
        selectionChange:function(rows){
            this.selectRows = rows
        },
        loadCurrentPage:function(page){
            if(!page){
                page = 1;
            }
            var that = this;
            $.ajax({
                url: "/template/query",
                async: true,
                type: 'POST',
                headers: {'Content-Type': 'application/json'},
                dataType:"json",  
                data: JSON.stringify({
                    parentId:this.groupId,
                    search:this.searchText,
                    start:(page-1)*this.pages.pageSize,
                    count:this.pages.pageSize
                }),
                success:function(result){
                    that.datas = result;
                    that.$forceUpdate();
                },
                error:function(){
                    that.$Message.error('加载失败');
                }
            });
        }

    },
    mounted(){
        let that = this;
        this.loadData();
        this.removeMsgListener = $msgCenter.addListener(this.$context.msgKey,function(){
            that.$forceUpdate();
        });
    },
    destory(){
        this.removeMsgListener();
    }
}
</script>

<style scoped>
.template-list-root{
    width: 1180px;
    margin: 0 auto;
    padding-top: 30px;
}
.template-l-opetion-bar{
    margin: 0 auto;
    width: 1180px;
    text-align: right;
}

.template-list-search{
    width: auto;
}

.template-list-bar-item:last-child{
    margin-right: 0 !important;
}

.template-list-bar-item{
    margin-right: 15px;
}

.template-list-table{
    margin: 0 auto;
    margin-top:15px;
    width: 1180px;
    border-top: solid 1px rgb(200, 200, 200);
}

.template-list-page{
    margin: 0 auto;
    margin-top:30px;
    width: 1180px;
    text-align: right;
}

.template-list-model .ivu-modal{
    width: 99% !important;
    position: fixed;
    top: 10px;
    bottom: 0;
    left: 5px;
    right: 5px;
}
.template-list-model .ivu-modal-content{
    height: 99%;
}
.template-list-model .ivu-modal-body{
    box-sizing: border-box;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 57px;
}

.template-list-model .ivu-modal-footer{
    position: absolute;
    bottom: 0;
    width: 100%;
}

.template-l-label{
    width: 100px;
    text-align: right;
    padding-right: 12px;
    margin-top: 10px;
}

.template-l-label::before{
    content: '*';
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
}
.template-l-content{
    position: absolute;
    top: 170px;
    left: 116px;
    right: 15px;
    bottom: 15px;
    border: solid 1px rgb(200, 200, 200);
    border-radius: 5px;
    overflow: hidden;
}

</style>
